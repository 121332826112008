import cron from '@/views/private/cron'

const Dashboard = () => import(/* webpackPrefetch: true */ '@/views/private/Dashboard.vue');
const License = () => import(/* webpackPrefetch: true */ '@/views/private/license/LicenseList.vue');
const Member = () => import(/* webpackPrefetch: true */ '@/views/private/member/MemberList.vue');
const MemberRedeem = () => import(/* webpackPrefetch: true */ '@/views/private/member/MemberRedeemList.vue');
const Settings = () => import(/* webpackPrefetch: true */ '@/views/private/Settings.vue');

/* COURSE ROUTE */
const Order = () => import(/* webpackPrefetch: true */ '@/views/private/order/ListOrder.vue');
const OrderLicense = () => import(/* webpackPrefetch: true */ '@/views/private/order/OrderLicense.vue')

// Company
const Company = () => import(/* webpackPrefetch: true */ '@/views/private/company/CompanyList.vue');
const CreateCompany = () => import(/* webpackPrefetch: true */ '@/views/private/company/Create.vue');
const UpdateCompany = () => import(/* webpackPrefetch: true */ '@/views/private/company/Edit.vue');
const DetailCompany = () => import(/* webpackPrefetch: true */ '@/views/private/company/Detail.vue');

//HALAMAN
const PageList = () => import(/* webpackPrefetch: true */ '@/views/private/company/pages/PagesList.vue');
const CreatePages = () => import(/* webpackPrefetch: true */ '@/views/private/company/pages/CreatePages.vue');
const UpdatePage = () => import(/* webpackPrefetch: true */ '@/views/private/company/pages/UpdatePages.vue');

//Trainer
const Trainer = () => import(/* webpackPrefetch: true */ '@/views/private/company/trainer/TrainerList.vue');
const UpdateTrainer = () => import(/* webpackPrefetch: true */ '@/views/private/company/trainer/EditTrainer.vue');
const DetailTrainer = () => import(/* webpackPrefetch: true */ '@/views/private/company/trainer/DetailTrainer.vue');

// Merchant
const Merchant = () => import(/* webpackPrefetch: true */ '@/views/private/merchant/MerchantList.vue');

// Users
const Users = () => import(/* webpackPrefetch: true */ '@/views/private/users/UsersList.vue');

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard"
    }
  },
  {
    path: "/company",
    name: "company",
    component: Company,
    meta: {
      title: "Company List"
    }
  },
  {
    path: "/company/create-company",
    name: "create-company",
    component: CreateCompany,
    meta: {
      title: "Create Company"
    }
  },
  {
    path: "/company/update-company/:id",
    name: "update-company",
    component: UpdateCompany,
    meta: {
      title: "Update Company"
    }
  },
  {
    path: "/company/detail-company/:id",
    name: "detail-company",
    component: DetailCompany,
    meta: {
      title: "Detail Company"
    }
  },

  {
    path: "/company/page-list/:id",
    name: "page-list",
    component: PageList,
    meta: {
      title: "Pages List"
    }
  },
  {
    path: "/company/create-page/:id",
    name: "create-page",
    component: CreatePages,
    meta: {
      title: "Create Page"
    }
  },
  {
    path: "/company/update-page/:slug/:id",
    name: "update-page",
    component: UpdatePage,
    meta: {
      title: "Update Page"
    }
  },
  
  {
    path: "/company/trainer-list/:id",
    name: "trainer-list",
    component: Trainer,
    meta: {
      title: "Trainer List"
    }
  },
  {
    path: "/company/update-trainer/:company_id/:id",
    name: "update-trainer",
    component: UpdateTrainer,
    meta: {
      title: "Update Trainer"
    }
  },
  {
    path: "/company/detail-trainer/:company_id/:id",
    name: "detail-trainer",
    component: DetailTrainer,
    meta: {
      title: "Detail Trainer"
    }
  },
  {
    path: "/merchant",
    name: "merchant",
    component: Merchant,
    meta: {
      title: "List Merchant"
    }
  },
  {
    path: "/license",
    name: "License",
    component: License,
    meta: {
      title: "License List"
    }
  },
  {
    path: "/settings",
    name: "Setting",
    component: Settings,
    meta: {
      title: "Setting"
    }
  },

  /* MEMBER ROUTE */
  {
    path: "/member/:id",
    name: "Member",
    component: Member,
    meta: {
      title: "Member List"
    }
  },

  {
    path: "/redeem/member",
    name: "Member Redeeem",
    component: MemberRedeem,
    meta: {
      title: "Member Redeem List"
    }
  },

  /* COURSE ROUTE */
  {
    path: "/order",
    name: "List Order",
    component: Order,
    meta: {
      title: "List Order"
    }
  },
  {
    path: "/order/license/:id",
    name: "List Lisence Order",
    component: OrderLicense,
    meta: {
      title: "List Lisence Order"
    }
  },
  /* COURSE ROUTE END */
  {
    path: "/cron-monitoring",
    name: "Cron Monitoring",
    component: cron.index,
    meta: {
      title: "Cron Monitoring"
    }
  },

  /* USER ROUTE */
  {
    path: "/users/:company_id",
    name: "management-users",
    component: Users,
    meta: {
      title: "Users List"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
	return { ...route, meta }
})